import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation,useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Radio,
  Modal,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import MDL_ADD_UNIT from "./mdl-add-unit/mdl-add-unit";
import MDL_BULK_UNIT from "./mdl-upload-bulk-units/mdl-upload-bulk-units";
import MDL_SUCCESS from "../../../components/cmp-success/cmp-success";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_UPDATE_UNIT from "./mdl-update-unit/mdl-update-unit";
import CMP_UPDATE_BUILDING from "./cmp-update-building/cmp-update-building";
import * as XLSX from "xlsx";
import { useToasts } from "react-toast-notifications";
import MDL_WARRANTY from "../building-management/mdl-warranty/Warranty";
import {
  getSingleBuilding,
  addSingleUnit,
  addBulkUnit,
  deleteUnit,
  getListofScheduler,
} from "../../../constants";
import "./update-building.css";

const UpdateBuilding = () => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo } = useContext(AppContext);

  const [var_building_order, set_building_order] = useState({});
  const [var_mdl_addunit_open, set_mdl_addunit_open] = useState();
  const [var_mdl_bulkunit_open, set_mdl_bulkunit_open] = useState();
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [var_mdl_updateunit_open, set_mdl_updateunit_open] = useState(false);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [edited_item, setEditedItem] = useState({});
  const [units, setUnits] = useState([]);
  const [user, setUser] = userInfo;
  const [isError, setISError] = useState();
  const [var_add_building, set_var_add_building] = useState({});
  const [success_message, setSuccessMessage] = useState();
  const [schedulers, setSchedulers] = useState();
  const [var_mdl_warranty, set_mdl_warranty] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { buildingID } = useParams();
  const building_id =  location.state ? location.state.building_id : buildingID;
  const { addToast } = useToasts();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

  useEffect(() => {
    if (building_id) {
      getBuildingData(false);
      getSchedulerData()
    }
  }, [var_mdl_warranty,building_id]);
  /**
   *
   * @param {*} isForUnits = "To check weather get full building details or just need units data."
   */
  async function getBuildingData(isForUnits) {
    set_mdl_progress(true);
    await fetch(getSingleBuilding + building_id)
      .then((response) => response.json())
      .then((res) => {
        const final_data = res.data[0];
        var dummy_data = {};
        if (final_data) {
          if (isForUnits) {
            setUnits(final_data.units ? final_data.units : []);
          } else {
            dummy_data.building_name = final_data.building_name;
            dummy_data.building_address = final_data.building_address;
            dummy_data.building_city = final_data.building_city;
            dummy_data.building_po_mo_wo = final_data.building_po_mo_wo;
            dummy_data.location_id = final_data.location_id;
            dummy_data.customer_name = final_data.customer_name;
            dummy_data.email = final_data.email;
            dummy_data.customer_email = final_data.customer_email;
            dummy_data.master_pin = final_data.master_pin;
            dummy_data.master_pin2 = final_data.master_pin2;
            dummy_data.master_pin3 = final_data.master_pin3;
            dummy_data.master_pin4 = final_data.master_pin4;
            dummy_data.tax = final_data.tax;
            dummy_data.building_pin = final_data.building_pin;
            var_building_order.value = final_data.building_po_mo_wo
              ?.toString()
              .toLowerCase();

            dummy_data.customer_id = final_data.customer_id;
            dummy_data.TimeWindow1 = final_data.TimeWindow1;
            dummy_data.TimeWindow2 = final_data.TimeWindow2;
            dummy_data.scheduler_id = final_data.scheduler_id;
            dummy_data.warranty_count = final_data.warranty_count;
            dummy_data.warranty_used = final_data.warranty_used;
            set_var_add_building(dummy_data);
            setUnits(final_data.units ? final_data.units : []);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      set_mdl_progress(false);
  }

  async function getSchedulerData() {
    await fetch(getListofScheduler)
      .then((response) => response.json())
      .then((res) => {
        const formattedSchedulers = res.data.map(scheduler => ({
          value: scheduler.value,
          text: `${scheduler.text} (${scheduler.email})`
        }));
        setSchedulers(formattedSchedulers);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then(async (d) => {
      if (d && d[0]) {
        if (d[0].unit) {
          const requestOptionsUnit = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + user.token,
            },
            body: JSON.stringify({ unit: d }),
          };

          await fetch(addBulkUnit + `${building_id}`, requestOptionsUnit)
            .then((response) => response.json())
            .then((res) => {
              if (res.code && res.code === 401) {
                addToast("Session expired. Please Login again.", {
                  appearance: "error",
                  autoDismiss: true,
                });
                //SessionExpire();
                localStorage.removeItem("user");
                setUser(null);
              } else {
                if (res.code === 200) {
                  addToast(res.success, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  getBuildingData(true);
                } else {
                  addToast(res.success, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error unit:", error);
            });

          setISError(false);
        }
      }
      //setItems(d);
    });
  };

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/


  // const onOpen_mdl_addunit = () => {
  //   set_mdl_addunit_open(true);
  // };

  // const onOpen_mdl_bulkunits = () => {
  //   set_mdl_bulkunit_open(true);
  // };

  // const onOpen_mdl_updateunit = (data) => {
  //   set_mdl_updateunit_open(true);
  //   setEditedItem(data);
  // };

  const removeValue = async (index) => {
    if (units.length > 0) {
      var unit_array_copy = [...units];
      var selectedUnit = unit_array_copy[index];
      if (selectedUnit && selectedUnit.uuid) {
        const requestOptionsUnit = {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(deleteUnit + `${selectedUnit.uuid}`, requestOptionsUnit)
          .then((response) => response.json())
          .then((res) => {
            if (res.code == 200) {
              if (index > -1) {
                unit_array_copy.splice(index, 1);
                setUnits(unit_array_copy);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else {
      addToast("Building must have at least 1 unit.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const deleteAllUnits = async (index) => {
    if (units.length > 0) {
      const requestOptionsUnit = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(
        deleteUnit + `?building_id=${building_id}`,
        requestOptionsUnit
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 200) {
            // if (index > -1) {
            //   // unit_array_copy.splice(index, 1);
            //   // setUnits(unit_array_copy);
            // }
            addToast("Unit(s) deleted successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
            if (location.state) {
              getBuildingData(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      addToast("Building must have at least 1 unit.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const success_function = () => {
    set_mdl_success(false);
    history.push("/building-management");
  };

  const add_single_unit = async (
    unit,
    beds,
    town_house,
    is_warranty,
    warranty_type,
    w_pest,
    w_treatment,
    w_expirey_date
  ) => {
    var unit_array_copy = [];
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({
        unit,
        bedroom_count: beds,
        building_id,
        town_house: town_house ? 1 : 0,
        is_warranty,
        warranty_type,
        w_pest,
        w_treatment,
        w_expirey_date,
        user_id: user.user_id,
      }),
    };
    await fetch(addSingleUnit, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          setUser(null);
        } else {
          if (res.code === 200) {
            getBuildingData(true);
            addToast(res.success, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.success, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const onChangeCustomer = (e, { value }) => {
  //   var temp = { ...var_add_building };
  //   temp.customer_id = value;
  //   set_var_add_building(temp);
  //   setISError(false);
  // };
  const onOpen_mdl_warranty = () => {
    set_mdl_warranty(true);
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <>
      <div className="app-content">
        <div className="updated-building-container">
          <div className="app-backto-prev-page">
            <div className="app-goback-button" onClick={history.goBack}>
              <Icon name="arrow left" /> Go Back
            </div>
          </div>
          <CMP_UPDATE_BUILDING 
          var_building_order={var_building_order}
          var_add_building={var_add_building}
          schedulers={schedulers}
          set_var_add_building={set_var_add_building}
          set_building_order={set_building_order}
          setSuccessMessage={setSuccessMessage}
          set_mdl_success={set_mdl_success}
          setISError={setISError}

          />
          <div className="building-add-unit-container">
            <div className="app-card">
              <div className="buildding-add-unit-header">
                <div className="building-add-unit-title">
                  Units ({units.length})
                </div>

                <div className="building-add-unit-actions">
                  <Button color="red" onClick={() => onOpen_mdl_warranty()}>
                    Edit Warranty
                  </Button>
                  <Button color="orange" onClick={deleteAllUnits}>
                    Delete All Units
                  </Button>

                  {/* <Button color="orange" onClick={() => {}}>
                    Delete All Units
                  </Button>*/}
                </div>
              </div>
              <div className="building-added-unit-list">
                {units.map((val, index) => (
                  <div
                    key={index}
                    className={`added-unit-card ${
                      val.is_warranty === 1 && "warranty-card"
                    }`}
                  >
                    <div 
                     className={`added-unit-card-header ${
                      !val.warranty_valid && "warranty-expired"
                    }`}
                    >
                      <div className="warranty-badge">
                        <div className="warranty-text">Warranty {!val.warranty_valid ? "Expired" : "Valid"}</div>
                      </div>

                      <Button.Group className="add-unit-menu">
                        <Dropdown icon="ellipsis vertical">
                          <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={() => onOpen_mdl_updateunit(val)}>Edit</Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={removeValue.bind(this, index)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Button.Group>
                    </div>

                    <div className="added-unit-card-item">
                      <div className="unit-number">Unit {val.unit}</div>
                      <div className="number-beds">
                        Beds: {val.bedroom_count}
                      </div>
                    </div>
                    {val.floor?.includes("TH ") ? (
                      <div className="added-unit-card-item">
                        Townhouse: {val.town_house === 1 ? "Yes" : "No"}
                      </div>
                    ) : (
                      <div className="added-unit-card-item">{val.floor}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/***** MODAL: ADD UNIT **************************************************************************/}
      <Modal
        id="mdl-add-unit"
        dimmer={"inverted"}
        open={var_mdl_addunit_open}
        onClose={() => set_mdl_addunit_open(false)}
      >
        <MDL_ADD_UNIT
          set_mdl_open={set_mdl_addunit_open}
          add_single_unit={add_single_unit}
        />
      </Modal>

      {/***** END MODAL: ADD UNIT **************************************************************************/}
      {/***** MODAL: Warrenty **************************************************************************/}
      <Modal
        id="mdl-warranty"
        style={{ overflow: "visible" }}
        dimmer={"inverted"}
        size="large"
        open={var_mdl_warranty}
        onClose={() => set_mdl_warranty(false)}
      >
        <MDL_WARRANTY building_id={building_id} var_add_building={var_add_building} set_mdl_open={set_mdl_warranty}></MDL_WARRANTY>
      </Modal>
      {/***** END MODAL: Warrenty **************************************************************************/}

      {/***** MODAL: UPLOAD BULK UNITS **************************************************************************/}
      <Modal
        id="mdl-upload-bulkunit"
        dimmer={"inverted"}
        open={var_mdl_bulkunit_open}
        onClose={() => set_mdl_bulkunit_open(false)}
      >
        <MDL_BULK_UNIT
          set_mdl_open={set_mdl_bulkunit_open}
          onChangeEvent={readExcel}
        />
      </Modal>

      {/***** END MODAL: UPLOAD BULK UNITS **************************************************************************/}

      {/***** MODAL: SUCCESS **************************************************************************/}
      <Modal
        id="mdl-success"
        dimmer={"inverted"}
        open={var_mdl_success}
        onClose={() => set_mdl_success(false)}
      >
        <MDL_SUCCESS
          set_mdl_open={set_mdl_success}
          var_modaltitle="Success"
          var_message={success_message}
          success_function={success_function}
        ></MDL_SUCCESS>
      </Modal>

      {/***** END MODAL: SUCCESS **************************************************************************/}

      {/***** MODAL: UPDATE UNIT **************************************************************************/}
      <Modal
        id="mdl-update-unit"
        dimmer={"inverted"}
        open={var_mdl_updateunit_open}
        onClose={() => set_mdl_updateunit_open(false)}
      >
        <MDL_UPDATE_UNIT
          set_mdl_open={set_mdl_updateunit_open}
          model_data={edited_item}
          getBuildingData={getBuildingData}
        />
      </Modal>

      {/***** END MODAL: ADD UNIT **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process might take some time. Stay on this page untill this process finish."
          }
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
};

export default UpdateBuilding;
