import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../../../Context/AppContext";
import { Form, Input, Button, Radio, Icon } from "semantic-ui-react";

import { updateBuilding } from "../../../../constants";

export default function CMP_UPDATE_BUILDING({
  var_building_order,
  var_add_building,
  schedulers,
  set_building_order,
  set_var_add_building,
  setSuccessMessage,
  set_mdl_success,
  setISError,
}) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo,validateTextField } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [isUpdate, setIsUpdate] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const [errors, set_errors] = useState({});
  const [isMasterPinVisible, setIsMasterPinVisible] = useState(false);
  const [isPinVisible, setIsPinVisible] = useState(false);
  

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const toggleMasterPinVisibility = () => {
    setIsMasterPinVisible(!isMasterPinVisible);
  };
  const togglePinVisibility = () => {
    setIsPinVisible(!isPinVisible);
  };
  const onChangeBuildingOrder = (e, { value }) => {
    set_building_order({ value });
    var_add_building.building_po_mo_wo = value;
  };

  const onChangeScheduler = (e, { value }) => {
    var temp = { ...var_add_building };
    temp.scheduler_id = value;
    set_var_add_building(temp);
    setISError(false);
  };

  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setISError(false);
    set_errors({ ...errors, [name]: null });
    var temp_error = { ...errors };

    if (name === "email" || name === "customer_email") {
        var pattern_email = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern_email.test(value)) {
            temp_error[name] = "Please enter a valid email address.";
        } else {
            temp_error[name] = null;
        }
    }

    if (name === "master_pin" || name === "master_pin2" || name === "master_pin3" || name === "master_pin4"|| name === "building_pin") {
        var pattern_number = /^[0-9]*$/;
        if (!pattern_number.test(value)) {
          temp_error[name] = "Please enter only numbers.";
        } else {
          temp_error[name] = null;
        }
    }

    if (name === "customer_name") {
      if (validateTextField(value) === false) {
        temp_error[name] = "Please enter valid name.";
      } else {
        temp_error[name] = null;
      }
    }

    if (name === "location_id") {
      if (value.length === 1  || value.length === 0) {
        temp_error[name] = "Please enter valid location id.";
      } else {
        temp_error[name] = null;
      }
    }

    set_errors(temp_error);
    set_var_add_building({ ...var_add_building, [name]: value });
};

  function isValidated() {
    var temp_error = { ...errors };
    var isValid = true;

    if (!var_add_building || !var_add_building.hasOwnProperty('building_name') || var_add_building.building_name === "") {
      temp_error.building_name = "Please add building name.";
      isValid = false;
    }
    if (!var_add_building || !var_add_building.hasOwnProperty('location_id') || var_add_building.location_id === "") {
      temp_error.location_id = "Please add building code.";
      isValid = false;
    }
    if (!var_add_building || !var_add_building.hasOwnProperty('TimeWindow1') || var_add_building.TimeWindow1 === "") {
      temp_error.TimeWindow1 = "Please add time window first.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('TimeWindow2') || var_add_building.TimeWindow2 === "") {
      temp_error.TimeWindow2 = "Please add Time window second.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('customer_name') || var_add_building.customer_name === "") {
      temp_error.customer_name = "Please add customer name.";
      isValid = false;
    }

    // if (var_add_building?.customer_email === "" || !var_add_building?.customer_email) {
    //   temp_error.customer_email = "Please add customer email.";
    //   isValid = false;
    // }

    // if (!var_add_building || !var_add_building.hasOwnProperty('master_pin') || var_add_building.master_pin === "") {
    //   temp_error.master_pin = "Please add master pin.";
    //   isValid = false;
    // }
    

    if (var_add_building?.master_pin?.length !== 0 && (var_add_building?.master_pin?.length > 7 || var_add_building?.master_pin?.length < 7)) {
      temp_error.master_pin = "Master pin length must be 7.";
      isValid = false;
    }

    if (var_add_building?.master_pin2?.length !== 0 && (var_add_building?.master_pin2?.length > 7 || var_add_building?.master_pin2?.length < 7)) {
      temp_error.master_pin2 = "Master pin length must be 7.";
      isValid = false;
    }

    if (var_add_building?.master_pin3?.length !== 0 && (var_add_building?.master_pin3?.length > 7|| var_add_building?.master_pin3?.length < 7)) {
      temp_error.master_pin3 = "Master pin length must be 7.";
      isValid = false;
    }

    if (var_add_building?.master_pin4?.length !== 0 && (var_add_building?.master_pin4?.length > 7 || var_add_building?.master_pin4?.length < 7)) {
      temp_error.master_pin4 = "Master pin length must be 7.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('building_pin') || var_add_building.building_pin === "") {
      temp_error.building_pin = "Please add pin.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('email') || var_add_building.email === "") {
      temp_error.email = "Please add building email.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('scheduler_id') || var_add_building.scheduler_id === "") {
      temp_error.email = "Please add building email.";
      isValid = false;
    }
    if (isValid) {
      set_errors({});
    } else {
      set_errors(temp_error);
    }
    return isValid;
  }

  const update_building = async () => {
    if(isValidated()){ 
    const { state } = location;
    const { building_id } = state;
    var_add_building.pin=var_add_building?.building_pin;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify(var_add_building),
    };
    await fetch(updateBuilding + `${building_id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          setUser(null);
        } else {
          if (res.code === 200) {
            setSuccessMessage("Building information updated successfully.");
            set_mdl_success(true);
            setIsUpdate(false);
          } else {
            addToast(res.failed, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        addToast("Some error while updating building. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <Form>
      <div className="app-card">
        <div className="app-card-header">
          <div className="app-card-title">Update Building</div>
        </div>

        <div className="app-card-body">
          <Form.Group>
            <Form.Field
              label="Building Name"
              control={Input}
              name="building_name"
              //onChange={onChangeInput}
              required
              value={var_add_building.building_name}
              placeholder="Write building name"
              error={errors.building_name}
            />
            <Form.Field
              label="City"
              control={Input}
              placeholder="Write city"
              name="building_city"
              value={var_add_building.building_city}
              onChange={onChangeInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              label="Address"
              control={Input}
              placeholder="Write address"
              name="building_address"
              value={var_add_building.building_address}
              onChange={onChangeInput}
            />
            <Form.Field
              label="Building Code"
              required
              control={Input}
              placeholder="Write location code"
              name="location_id"
              value={var_add_building.location_id}
              onChange={onChangeInput}
              error={errors.location_id}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              label="Time Window One"
              control={Input}
              name="TimeWindow1"
              onChange={onChangeInput}
              required
              value={var_add_building.TimeWindow1}
              placeholder="Write time eg. ( 8am - 12pm )"
              error={errors.TimeWindow1}
            />
            <Form.Field
              label="Time Window Second"
              control={Input}
              name="TimeWindow2"
              onChange={onChangeInput}
              required
              value={var_add_building.TimeWindow2}
              placeholder="Write time eg. ( 12pm - 5pm )"
              error={errors.TimeWindow2}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              label="Customer Name"
              required
              control={Input}
              placeholder="Write customer name"
              value={var_add_building.customer_name}
              name="customer_name"
              onChange={onChangeInput}
              error={errors.customer_name}
            />
            <Form.Field
              label="Customer Email"
              control={Input}
              value={var_add_building.customer_email}
              placeholder="Write customer email..."
              name="customer_email"
              onChange={onChangeInput}
              error={errors.customer_email}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              label="Pin"
              className="pin-class"
              type={"text"}
              control={Input}
              value={var_add_building?.building_pin}
              placeholder="Write pin here"
              name="building_pin"
              onChange={onChangeInput}
              error={errors.building_pin}
              disabled={true}
            />
            <Form.Field
              label="Master Pin 1"
              type={"text"}
              control={Input}
              value={var_add_building.master_pin}
              placeholder="Write master pin here"
              name="master_pin"
              autoComplete="new-password"
              onChange={onChangeInput}
              error={errors.master_pin}
            />
          </Form.Group>
          <Form.Group>
          <Form.Field
              label="Master Pin 2"
              type={"text"}
              control={Input}
              value={var_add_building.master_pin2}
              placeholder="Write master pin here"
              name="master_pin2"
              autoComplete="new-password"
              onChange={onChangeInput}
              error={errors.master_pin2}
            />
            <Form.Field
              label="Master Pin 3"
              type={"text"}
              control={Input}
              value={var_add_building.master_pin3}
              placeholder="Write master pin here"
              name="master_pin3"
              autoComplete="new-password"
              onChange={onChangeInput}
              error={errors.master_pin3}
            />
          </Form.Group>
          <Form.Group>
          <Form.Field
              label="Master Pin 4"
              type={"text"}
              control={Input}
              value={var_add_building.master_pin4}
              placeholder="Write master pin here"
              name="master_pin4"
              autoComplete="new-password"
              onChange={onChangeInput}
              error={errors.master_pin4}
            />
            <Form.Field
              label="Building Email"
              required
              control={Input}
              value={var_add_building.email}
              placeholder="Write building email (seprate by , )"
              name="email"
              onChange={onChangeInput}
              error={errors.email}
            />
          </Form.Group>
          <Form.Group>
          {schedulers?.length &&
            <Form.Select
            label="Scheduler"
            placeholder="Select scheduler"
            fluid
            required
            value={var_add_building.scheduler_id}
            onChange={onChangeScheduler}
            name="scheduler_id"
            error={errors.scheduler_id}
            options={schedulers?.map(option => ({
              key: option.value,
              value: option.value,
              text: (
                <div>
                  {option.text.split(' ')[0]}{' '}
                  <span style={{ color: 'lightgray' }}>
                    {option.text.split(' ')[1]}
                  </span>
                </div>
              )
            }))}
          />
        }
          <Form.Field
              label="Tax %"
              className="pin-class"
              type={"text"}
              control={Input}
              value={var_add_building?.tax}
              name="building_pin"
              onChange={onChangeInput}
              error={errors.building_pin}
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
          <Form.Field>
              <label>Building Order</label>
              <Radio
                label="Purchase Order Number"
                name="buildingOrder"
                value="po"
                checked={
                  var_building_order.value?.toString().toLowerCase() === "po"
                }
                onChange={onChangeBuildingOrder}
              />
              <Radio
                label="Work Order Number"
                name="buildingOrder"
                value="wo"
                checked={
                  var_building_order.value?.toString().toLowerCase() === "wo"
                }
                onChange={onChangeBuildingOrder}
              />
              <Radio
                label="Maintenance Order Number"
                name="buildingOrder"
                value="mo"
                checked={
                  var_building_order.value?.toString().toLowerCase() === "mo"
                }
                onChange={onChangeBuildingOrder}
              />
              <Radio
                label="None"
                name="buildingOrder"
                value={null}
                defaultChecked
                checked={
                  !var_building_order.value ||
                  var_building_order.value === null ||
                  var_building_order.value === "null"
                }
                onChange={onChangeBuildingOrder}
              />
          </Form.Field>
          </Form.Group>
          <div className="building-update-submit">
            <Button color="red" onClick={update_building}>
              Update
            </Button>
          </div>
        </div>
      </div>
      <div className="page-form-submit">
        <div className="page-form-submit-button"></div>
      </div>
    </Form>
  );
}
